import React from 'react'

const Banner = () => {
    return (
        <div className="banner">
            <img src="./IMG_0071.JPG" alt="Andrew Mullan"></img>
            <h1>Andrew Mullan</h1>
            <h2>Javascript, React, Ruby, Ruby on Rails Developer</h2>
        </div>
    )
}

export default Banner